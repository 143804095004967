import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import {TokenService} from '@/services/token.service';
import store from '@/store';
import {ACTIONS, GETTERS, MUTATIONS} from '@/store/namespaces';
import {UserRoleEnum} from '@/services/api-clients';
import i18n from '@/i18n/config';
import {LF} from '@/i18n/LF';
import {AuthService} from '@/services/auth.service';

Vue.use(VueRouter);

// eslint-disable-next-line no-shadow
enum Routes {
  Login = '/login',
  App = '/',
  ApplicationAccountsList = '/application-accounts',
  AddApplicationAccount = '/application-accounts/form',
  EditApplicationAccount = '/application-accounts/form/:id',
  DriversList = '/drivers',
  AddDriver = '/drivers/form',
  EditDriver = '/drivers/form/:id',
  VehiclesList = '/vehicles',
  AddVehicle = '/vehicles/form',
  EditVehicle = '/vehicles/form/:id',
  SettlementsList = '/settlements',
  AddSettlement = '/settlements/form',
  EditSettlement = '/settlements/form/:id',
  ExportSettlement = '/settlements/export',
  ExportSettlementHistories = '/settlements/export-histories',
  ImportSettlement = '/settlements/import',
  OrganisationsList = '/organisations',
  AddOrganisation = '/organisations/form',
  EditOrganisation = '/organisations/form/:id',
  UsersList = '/users',
  AddUser = '/users/form',
  EditUser = '/users/form/:id',
  AuditList = '/audit',
  Articles = '/articles/:groupSlug',
  Article = '/articles/:groupSlug/:articleSlug',
  PaymentSettings = '/payment-settings',
  SettlementSettings = '/settlement-settings',
  Payoffs = '/payoffs',
  AddPayoff = '/payoffs/form',
  EditPayoff = 'payoffs/form/:id',
  ImportPayoffs = '/payoffs-import',
  Statistics = '/statistics',
  StatisticsSettings = '/statistics-settings',
  Installments = '/installments',
  AddInstallment = '/installments/form',
  EditInstallment = '/installments/form/:id',
  CashFlows = '/cash-flows',
  AddCashFlow = '/cash-flows/form',
  EditCashFlow = '/cash-flows/form/:id',
  VehiclesLog = '/vehicles-log',
  VehicleLogSettings = '/vehicle-log-settings',
  ReportSettings = '/report-settings',
  ReportsList = '/reports',
  EditReport = '/reports/form/:id',
  Dashboard = '/dashboard',
  ResourceSettings = '/resource-settings',
  CashFlowReports = '/cash-flow-reports',
  Pit11 = '/pit-11',
  DriverContactForms = '/driver-contact-forms',
  Invoices = '/invoices',
  DayOff = '/day-off',
  Debtors = '/debtors',
  Signature = '/signature',
  DocumentSettings = '/document-settings',
  VehiclesDocumentList = '/vehicles-document-list',
  DigitalSignatures = '/digital-signatures',
  HrTagSettings = '/hr-tag-settings',
  BonusCampaigns = '/bonus-campaigns',
  AddBonusCampaign = '/bonus-campaigns/form',
  EditBonusCampaign = '/bonus-campaigns/form/:id',
  DigitalSignaturesToSign = '/digital-signatures-to-sign',
  Mails = '/mails',
  MailRules = '/mail-rules',
  DriverVerification = '/driver-verification',
  EditMailRules = '/mail-rules/form/:id',
  OnlinePayments = '/online-payments',
}

const routes: Array<RouteConfig> = [
  {
    path: Routes.Login,
    name: 'Login',
    component: () => import('@/pages/Login.page.vue'),
    meta: {
      title: () => i18n.t(LF.Login),
      transition: 'fade',
      autoLogin: true,
    },
  },
  {
    path: '',
    component: () => import('@/templates/Main.template.vue'),
    children: [{
      path: Routes.App,
      redirect: Routes.Dashboard,
    }, {
      path: Routes.ApplicationAccountsList,
      component: () => import('@/pages/ApplicationAccounts/ApplicationAccountsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.ApplicationAccounts),
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (!store.getters[GETTERS.CAN_ACCESS_APPLICATION_ACCOUNTS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.AuditList,
      component: () => import('@/pages/Audit/AuditList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Audit),
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (!store.getters[GETTERS.USER_HAVE_ACCESS_TO_AUDIT_AREA]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.AddApplicationAccount,
      name: 'New application account form',
      component: () => import('@/pages/ApplicationAccounts/ApplicationAccountForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.ApplicationAccountCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditApplicationAccount,
      name: 'Edit application account',
      component: () => import('@/pages/ApplicationAccounts/ApplicationAccountForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.ApplicationAccountEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.DriversList,
      name: 'Drivers\' list',
      component: () => import('@/pages/Drivers/DriversList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Drivers),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.Debtors,
      name: 'Debtors',
      component: () => import('@/pages/Drivers/DebtorsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Debtors),
        requiresAuth: true,
        transition: 'fade'
      }
    },{
      path: Routes.DriverVerification,
      name: 'Driver verification',
      component: () => import('@/pages/Drivers/DriverVerificationList.page.vue'),
      meta: {
        title: () => i18n.t(LF.DriverVerification),
        requiresAuth: true,
        transition: 'fade'
      }
    }, {
      path: Routes.AddDriver,
      name: 'New driver form',
      component: () => import('@/pages/Drivers/DriverForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.DriverCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditDriver,
      name: 'Edit driver form',
      component: () => import('@/pages/Drivers/DriverForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.DriverEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.VehiclesList,
      name: 'Vehicles',
      component: () => import('@/pages/Vehicles/VehiclesList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Vehicles),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.VehiclesDocumentList,
      name: 'Vehicles documents',
      component: () => import('@/pages/VehiclesDocument/VehiclesDocumentList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Documents),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_MANAGE_VEHICLE_DOCUMENTS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.AddVehicle,
      name: 'New vehicle form',
      component: () => import('@/pages/Vehicles/VehicleForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.VehicleCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditVehicle,
      name: 'Edit vehicle form',
      component: () => import('@/pages/Vehicles/VehicleForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.VehicleEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.VehiclesLog,
      name: 'Vehicles log',
      component: () => import('@/pages/Vehicles/VehiclesLog.page.vue'),
      meta: {
        title: () => i18n.t(LF.VehiclesLog),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.DayOff,
      name: 'Day offs',
      component: () => import('@/pages/DayOffs/DayOffList.page.vue'),
      meta: {
        title: () => i18n.t(LF.DayOff),
        requiresAuth: true,
        transition: 'fade'
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_ADD_OR_REMOVE_DRIVER_DAYOFF]){
          next({ path: Routes.App });
        } else {
          next();
        }
      }
    }, {
      path: Routes.VehicleLogSettings,
      name: 'Vehicle log settings',
      component: () => import('@/pages/Settings/VehicleLogs/VehicleLogSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.VehicleLogSettings),
        requiresAuth: true,
        transition: 'fade',
        roles: [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin],
      },
    }, {
      path: Routes.SettlementsList,
      name: 'Settlements',
      component: () => import('@/pages/Settlements/SettlementsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Settlements),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddSettlement,
      name: 'New settlement form',
      component: () => import('@/pages/Settlements/SettlementForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.SettlementCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditSettlement,
      name: 'Edit settlement form',
      component: () => import('@/pages/Settlements/SettlementForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.SettlementCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.ExportSettlementHistories,
      name: 'Export settlements histories',
      component: () => import('@/pages/Settlements/SettlementExportHistory.page.vue'),
      meta: {
        title: () => i18n.t(LF.ExportHistory),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_EXPORT_SETTLEMENTS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.ImportSettlement,
      name: 'Import settlements',
      component: () => import('@/pages/Settlements/SettlementImport.page.vue'),
      meta: {
        title: () => i18n.t(LF.ImportSettlements),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_IMPORT_SETTLEMENTS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.Payoffs,
      name: 'Payoffs',
      component: () => import('@/pages/Payoffs/PayoffList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Payoffs),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditPayoff,
      name: 'Edit Payoff',
      component: () => import('@/pages/Payoffs/PayoffForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.PayoffEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddPayoff,
      name: 'Add Payoff',
      component: () => import('@/pages/Payoffs/PayoffForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.PayoffCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    },  {
      path: Routes.ImportPayoffs,
      name: 'Import Payoffs',
      component: () => import('@/pages/Payoffs/PayoffImport.page.vue'),
      meta: {
        title: () => i18n.t(LF.ImportPayoffsTitle),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_IMPORT_PAYOFFS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    },  {
      path: Routes.OrganisationsList,
      name: 'Organisations list',
      component: () => import('@/pages/Settings/Organisations/OrganisationsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Organisations),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.AddOrganisation,
      name: 'Add organisation',
      component: () => import('@/pages/Settings/Organisations/OrganisationForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.OrganisationCreate),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.EditOrganisation,
      name: 'Edit organisation',
      component: () => import('@/pages/Settings/Organisations/OrganisationForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.OrganisationEdit),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.UsersList,
      name: 'Users list',
      component: () => import('@/pages/Settings/Users/UsersList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Users),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin, UserRoleEnum.Admin],
        transition: 'fade',
      },
    }, {
      path: Routes.AddUser,
      name: 'Add user',
      component: () => import('@/pages/Settings/Users/UsersForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.UserCreate),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin, UserRoleEnum.Admin],
        transition: 'fade',
      },
    }, {
      path: Routes.EditUser,
      name: 'Edit user',
      component: () => import('@/pages/Settings/Users/UsersForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.UserEdit),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin, UserRoleEnum.Admin],
        transition: 'fade',
      },
    }, {
      path: Routes.PaymentSettings,
      name: 'Payment Settings',
      component: () => import('@/pages/Settings/Payments/PaymentSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.PaymentSettings),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.SettlementSettings,
      name: 'Settlement Settings',
      component: () => import('@/pages/Settings/Settlements/SettlementSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.SettlementsSettings),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.Articles,
      name: 'Article Groups',
      component: () => import('@/pages/Articles/ArticlesList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Articles),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.Article,
      name: 'Article Details',
      component: () => import('@/pages/Articles/ArticleDetails.page.vue'),
      meta: {
        title: () => i18n.t(LF.ArticleDetails),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.Statistics,
      name: 'Statistics',
      component: () => import('@/pages/Statistics/Statistics.page.vue'),
      meta: {
        title: () => i18n.t(LF.Statistics),
        requiresAuth: true,
        transition: 'fade',
        requireStatisticBundle: true,
      },
    }, {
      path: Routes.StatisticsSettings,
      name: 'Statistics Settings',
      component: () => import('@/pages/Settings/Statistics/StatisticsSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.StatisticsSettings),
        requiresAuth: true,
        transition: 'fade',
        roles: [UserRoleEnum.SystemAdmin],
      },
    }, {
      path: Routes.Installments,
      name: 'Installments list',
      component: () => import('@/pages/Installments/InstallmentsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Installments),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddInstallment,
      name: 'Add installment',
      component: () => import('@/pages/Installments/InstallmentForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.InstallmentSchemaCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditInstallment,
      name: 'Edit installment',
      component: () => import('@/pages/Installments/InstallmentForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.InstallmentSchemaEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.CashFlows,
      name: 'Cash flows list',
      component: () => import('@/pages/CashFlows/CashFlowsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.CashFlows),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddCashFlow,
      name: 'Add cash flow',
      component: () => import('@/pages/CashFlows/CashFlowForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.CashFlowCreate),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.EditCashFlow,
      name: 'Edit cash flow',
      component: () => import('@/pages/CashFlows/CashFlowForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.CashFlowEdit),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.ReportSettings,
      name: 'Report settings',
      component: () => import('@/pages/Settings/Reports/ReportSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.ReportSettings),
        requiresAuth: true,
        transition: 'fade',
        roles: [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin],
      },
    }, {
      path: Routes.ReportsList,
      name: 'Reports list',
      component: () => import('@/pages/Reports/ReportList.page.vue'),
      meta: {
        title: () => i18n.t(LF.ReportList),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_HAVE_ACCESS_TO_REPORT_AREA]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.EditReport,
      name: 'Edit report',
      component: () => import('@/pages/Reports/ReportForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.EditReport),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_HAVE_ACCESS_TO_REPORT_AREA]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.ResourceSettings,
      name: 'Resource settings',
      component: () => import('@/pages/Settings/Resources/ResourceSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.ResourceSettings),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    },{
      path: Routes.DocumentSettings,
      name: 'Document settings',
      component: () => import('@/pages/Settings/Documents/DocumentSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.DocumentSettings),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    },{
      path: Routes.HrTagSettings,
      name: 'Document settings',
      component: () => import('@/pages/Settings/HRTags/HrTagSettings.page.vue'),
      meta: {
        title: () => i18n.t(LF.HrTagSettings),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      },
    }, {
      path: Routes.Dashboard,
      name: 'Dashboard',
      component: () => import('@/pages/Dashboard/Dashboard.page.vue'),
      meta: {
        title: () => i18n.t(LF.Dashboard),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.CashFlowReports,
      name: 'Cash flow reports',
      component: () => import('@/pages/CashFlows/CashFlowsReportList.page.vue'),
      meta: {
        title: () => i18n.t(LF.CashFlowsReports),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.Pit11,
      name: 'Pit11',
      component: () => import('@/pages/Pit11/Pit11.page.vue'),
      meta: {
        title: () => i18n.t(LF.Pit11),
        requiresAuth: true,
        roles: [UserRoleEnum.SystemAdmin],
        transition: 'fade',
      }
    },  {
      path: Routes.DriverContactForms,
      name: 'DriverContactForms',
      component: () => import('@/pages/DriverContactForms/DriverContactFormList.page.vue'),
      meta: {
        title: () => i18n.t(LF.DriverContactFormList),
        requiresAuth: true,
        transition: 'fade',
      }
    }, {
      path: Routes.Invoices,
      name: 'Invoices',
      component: () => import('@/pages/Invoices/InvoicesList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Invoices),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from ,next) => {
        const isValid = UserRoleEnum.SystemAdmin === store.getters[GETTERS.USER_ROLE]
        || store.getters[GETTERS.USER_CAN_CREATE_INVOICE];

        if (isValid) {
          next()
        } else {
          next({ path: Routes.App })
        }
      }
    }, {
      path: Routes.Signature,
      name: 'Signature',
      component: () => import('@/pages/SignatureDevices/SignatureDevicesList.page.vue'),
      meta: {
        title: () => i18n.t(LF.DigitalSignature),
        requiresAuth: true,
        transition: 'fade',
        roles: [UserRoleEnum.DigitalSignatureDevice],
      }
    }, {
      path: Routes.DigitalSignatures,
      name: 'DigitalSignatures',
      component: () => import('@/pages/DigitalSignatures/DigitalSignaturesList.page.vue'),
      meta: {
        title: () => i18n.t(LF.DigitalSignature),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isValid = UserRoleEnum.SystemAdmin === store.getters[GETTERS.USER_ROLE] || store.getters[GETTERS.USER_HAVE_ACCESS_TO_DIGITAL_DOCUMENTS_MANAGE_AREA];

        if (isValid) {
          next()
        } else {
          next({ path: Routes.App })
        }
      }
    },{
      path: Routes.DigitalSignaturesToSign,
      name: 'DigitalSignaturesToSign',
      component: () => import('@/pages/DigitalSignatures/DigitalSignaturesToSign.page.vue'),
      meta: {
        title: () => i18n.t(LF.MissingDocumentsToSign),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isValid = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]) || store.getters[GETTERS.USER_CAN_VIEW_MISSING_DRIVERS_DIGITAL_DOCUMENTS];

        if (isValid) {
          next()
        } else {
          next({ path: Routes.App })
        }
      }
    }, {
      path: Routes.BonusCampaigns,
      name: 'Bonus campaigns list',
      component: () => import('@/pages/BonusCampaigns/BonusCampaignsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.BonusCampaigns),
        requiresAuth: true,
        transition: 'fade',
      },
    }, {
      path: Routes.AddBonusCampaign,
      name: 'Add bonus campaign',
      component: () => import('@/pages/BonusCampaigns/BonusCampaignForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.AddCampaign),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_MANAGE_BONUS_CAMPAIGNS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.EditBonusCampaign,
      name: 'Edit bonus campaign',
      component: () => import('@/pages/BonusCampaigns/BonusCampaignForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.EditCampaign),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        const isAdmin = [UserRoleEnum.Admin, UserRoleEnum.SystemAdmin].includes(store.getters[GETTERS.USER_ROLE]);
        if (!isAdmin && !store.getters[GETTERS.USER_CAN_MANAGE_BONUS_CAMPAIGNS]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.Mails,
      name: 'Mails',
      component: () => import('@/pages/Mails/MailsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Emails),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (store.getters[GETTERS.USER_ROLE] !== UserRoleEnum.SystemAdmin && !store.getters[GETTERS.USER_HAVE_ACCESS_TO_MAIL_AREA]) {
          next({ path: Routes.App })
        } else next();
      }
    }, {
      path: Routes.MailRules,
      name: 'MailRules',
      component: () => import('@/pages/MailRules/MailRulesList.page.vue'),
      meta: {
        title: () => i18n.t(LF.Emails),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (store.getters[GETTERS.USER_ROLE] !== UserRoleEnum.SystemAdmin && !store.getters[GETTERS.USER_CAN_MANAGE_MAIL_RULES]) {
          next({ path: Routes.App })
        } else next();
      }
    }, {
      path: Routes.EditMailRules,
      name: 'Edit mail rules',
      component: () => import('@/pages/MailRules/MailRulesForm.page.vue'),
      meta: {
        title: () => i18n.t(LF.EditMailRules),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (store.getters[GETTERS.USER_ROLE] !== UserRoleEnum.SystemAdmin && !store.getters[GETTERS.USER_CAN_MANAGE_MAIL_RULES]) {
          next({
            path: Routes.App,
          });
        } else {
          next();
        }
      },
    }, {
      path: Routes.OnlinePayments,
      name: 'Online payments list',
      component: () => import('@/pages/OnlinePayments/OnlinePaymentsList.page.vue'),
      meta: {
        title: () => i18n.t(LF.OnlinePayments),
        requiresAuth: true,
        transition: 'fade',
      },
      beforeEnter: async (to, from, next) => {
        if (!store.getters[GETTERS.USER_HAVE_ACCESS_TO_ONLINE_PAYMENTS]) {
          next({ path: Routes.App });
        } else next();
      }
    }, {
      path: '*',
      redirect: Routes.Login,
    }],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const language = localStorage.getItem('language');
  if (!language) {
    localStorage.setItem('language', 'Polish');
  } else {
    switch (language) {
      case 'English': i18n.locale = 'en'; break;
      case 'Ukrainian': i18n.locale = 'ua'; break;
      default: i18n.locale = 'pl'; break;
    }
  }

  const isLoggedIn = await AuthService.isLoggedIn();

  if (!store.getters[GETTERS.APP_INITIALIZED] && isLoggedIn) {
    const isSignatureDevice = await AuthService.isSignatureDevice();

    await store.dispatch(ACTIONS.GET_USER_INFO, isSignatureDevice);

    if (!isSignatureDevice) {
      await store.dispatch(ACTIONS.GET_ARTICLE_GROUPS);
    }

    store.commit(MUTATIONS.SET_INITIALIZED, true);
  }

  if (to?.meta?.requiresAuth && !isLoggedIn) {
    next({
      path: Routes.Login,
    });
  }

  if (to?.meta?.autoLogin && isLoggedIn) {
    next({
      path: Routes.DriversList,
    });
  }

  if (to?.meta?.roles) {
    const allowedRoles = to.meta.roles as UserRoleEnum[];
    const userRole = store.getters[GETTERS.USER_ROLE] as UserRoleEnum;
    if (!allowedRoles.includes(userRole)) {
      next({
        path: Routes.DriversList,
      });
    }
  }

  if (to?.meta?.requireStatisticBundle) {
    const bundleId = store.getters[GETTERS.USER_STATISTIC_BUNDLE] as number | undefined;
    if (!bundleId) {
      next({
        path: Routes.DriversList,
      });
    }
  }

  document.title = to?.meta?.title
    ? `${process.env.VUE_APP_WEBSITE_TITLE_BASE} - ${to.meta.title()}`
    : process.env.VUE_APP_WEBSITE_TITLE_BASE || 'ravapi';

  if (to.path !== Routes.Signature && store.getters[GETTERS.USER_ROLE] === UserRoleEnum.DigitalSignatureDevice) {
    next({ path: Routes.Signature })
  } else {
    next();
  }

});

export default router;
